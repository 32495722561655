import IconButton from '@mui/material/IconButton'
import q from 'qjuul'
import React, { useEffect, useRef, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Popper } from '@mui/material';
import MenuItem from './menuitem';
import { NavItems } from './navmenu';
import BetterBlur from '../../shared/components/betterblur';

export default function DropdownNavMenu() {
    const [open, setOpen] = React.useState(false);
    const ref = useRef()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    
    
    if (windowWidth >= 2060) return null
  return (
    <q.div po='relative'>
        <IconButton size='large' onClick={()=> setOpen(!open)}>
            <MenuIcon />
        </IconButton>
        <q.div ref={ref} baCo='red' po='absolute' le='15px' top='65px' z1 >
            <Popper open={open} style={{zIndex: 50}} anchorEl={ref.current} disablePortal placement='left-end' >
                <BetterBlur onBlur={() => setOpen(false)}>
                    <q.div wi='300px' z1 baCo='grey' fctl gap='0px' shadow={{baCo: '#131313bb', size: '5px'}}>
                        {NavItems.map((nav, index) => <MenuItem path={nav.path} label={nav.name} key={index} />)}
                    </q.div>
                </BetterBlur>
            </Popper>
        </q.div>
    </q.div>
  )
}
