import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { combindedReplayData } from '../../../shared/formatters/graph'

type IFormatType = ReturnType<typeof combindedReplayData>

interface IOwnProps {
  data: IFormatType[keyof IFormatType]
  _key: string
  type?: 'category' | 'number'
}

const Chart = (props: IOwnProps) => {
  const { data, _key, type = 'number' } = props

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        {props.data !== undefined && props.data?.length !== 0 ? (
          <ResponsiveContainer height={190} width="100%">
            <LineChart data={props.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} />
              <XAxis
                dataKey="timeFrame"
                domain={[
                  Math.min(data[0].timeFrame, data[data.length - 1].timeFrame),
                  Math.max(data[0].timeFrame, data[data.length - 1].timeFrame),
                ]}
                name="time"
                label={{
                  value: 'Time',
                  position: 'insideBottom',
                  dy: 10,
                  fill: '#616161',
                }}
                interval={1}
                ticks={props.data?.length < 15 ? data.map(x => x.timeFrame) : null}
                tickCount={props.data?.length > 15 ? 15 : props.data?.length + 1}
                type="number"
              />
              <YAxis
                dataKey={'value'}
                type={type}
                domain={[
                  Math.min(data[0].value, data[data.length - 1].value),
                  Math.max(data[0].value, data[data.length - 1].value),
                ]}
                interval={0}
                tickCount={3}
                label={{
                  value: _key,
                  angle: -90,
                  position: 'left',
                  dy: -15,
                  dx: 35,
                  fill: '#616161',
                }}
              />
              <Line dataKey={'value'} stroke="white" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="inherit" style={{ fontStyle: 'italic' }}>
            No ChartData
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default Chart