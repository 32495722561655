/* eslint-disable react-hooks/exhaustive-deps */
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import q from 'qjuul';
import { createContext, useMemo, useState } from 'react';
import DropdownNavMenu from './dropdownnavmenu';
import NavMenu from './navmenu';

interface IOwnProps {
  children: React.ReactNode;
}

const LeftNav = (props: IOwnProps) => {
  const [mode, setMode] = useState('dark');
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
        },
      }),
    [mode]
  );

  const ColorModeContext = createContext({ toggleColorMode: () => {} });
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const headerColor = '#080808';

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <q.div f1 fctc z1>
          <q.div he={'60px'} w100 fccl baCo={headerColor} shadow={{ baCo: headerColor + '99', size: ' 5px' }} z1 po='sticky' top='0px'>
            <q.div frcl >
              <DropdownNavMenu />
              <img src="/TM_Logo.png" alt="Trackmania logo" height={20} />
              <q.h3 ma={'0 20px'}>Trackmania Utilities</q.h3>
            </q.div>
          </q.div>
          <q.div f1 fctc maxw={'1400px'} ma="00px 0" w100 baCo="#212121" shadow={{ baCo: '#141414', size: '40px' }}>            
            <q.div f1 maxw={'calc(100% - 40px)'} w100 fctl po='relative'>
              <NavMenu />
              {props.children}
            </q.div>
          </q.div>
          <q.div he={'60px'} w100 fccc baCo={headerColor} shadow={{ baCo: headerColor + '99', size: ' 5px' }} z1>
            Created by Proxo & SindreMA
          </q.div>
        </q.div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default LeftNav;
