/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { useEffect, useRef } from "react"

interface IOwnProps  {
    onBlur : Function
    children?: React.ReactElement
    ignoreElements?: React.MutableRefObject<HTMLDivElement>[]
}

const BetterBlur = (props : IOwnProps) => {
    const { onBlur, children } = props

    function useOuterClick(callback) {
        const callbackRef : any = useRef()
        const innerRef : any = useRef()
        useEffect(() => { callbackRef.current = callback })
        useEffect(() => {
            document.addEventListener('mouseup', handleAction)
            document.addEventListener('keydown', handleAction)
            return () => {
                document.removeEventListener('mouseup', handleAction)
                document.removeEventListener('keydown', handleAction)
            }
            function handleAction(e) {
                  const tab =  new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const checkElements = props.ignoreElements && props.ignoreElements.length > 0 ? [innerRef, ...props.ignoreElements] : [innerRef]                        
                        if (e.type === 'keydown' && ( e.key === 'Tab' ||  e.keyCode === 9)) {

                            let isAllowedPress = false
                            for (const el of checkElements) {
                                const isAllowed = el.current && (el.current?.contains(e.target) || el.current === e.target)
                                if (isAllowed) {
                                    isAllowedPress = isAllowed
                                    break;
                                }
                            }
                            if (!isAllowedPress) {
                                resolve(true)
                            } else {
                                reject("No match")
                            }
                        }
                      }, 10);
                  })
                  
                  const clickAndPress =  new Promise((resolve, reject) => {
                    var isAClick = e.type === 'mouseup'
                    var hasValidCallback = !!callbackRef.current 

                    const checkElements = props.ignoreElements && props.ignoreElements.length > 0 ? [innerRef, ...props.ignoreElements] : [innerRef]
                    let isAllowedClick = false
                    for (const el of checkElements) {
                        const isAllowed = el.current && (el.current?.contains(e.target) || el.current === e.target)
                        if (isAllowed) {
                            isAllowedClick = isAllowed
                            break;
                        }
                    }

                    var keypress = e.type === 'keydown' && e.keyCode === 27
                    if ((isAClick && !isAllowedClick && hasValidCallback) || keypress) resolve(true)
                    else reject("No match")
                })
                
                Promise.any([tab, clickAndPress]).then(()=> {
                    callbackRef.current(e)
                }).catch(x=> {})
            }
        }, [])
        return innerRef
    }

    if (!children) return null
    if (onBlur && typeof onBlur === 'function') {
        const innerRef = useOuterClick(onBlur)
        return React.cloneElement(children, {ref: innerRef})
    } else return (children)
}

export default BetterBlur

