import { Button, TextField } from '@mui/material';
import q from 'qjuul';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/css';
import Spacer from '../../shared/components/spacer';
import ReplayStats from './stats';
import { fetchReplayData } from '../../shared/fetchers/fetchReplayData';
import furball from 'wa-furball';
import Loading from '../../shared/components/loading';
import { ReplayData } from '../../shared/model/Replay';

const ActionKeysPage = () => {
  const [searchBox, setSearchBox] = useState('https://trackmania.exchange/replays/download/96534');
  const [replayData, setReplayData] = useState<ReplayData>();
  const [loading, setLoading] = useState(false);

  const cssNote = css`
    code {
      white-space: pre-line !important;
    }
  `;

  const search = () => {
    if (!searchBox) return null;
    setLoading(true);
    fetchReplayData(searchBox).then((data) => {
      setReplayData(data);
    }).catch((error) => {
      furball.error("Something went wrong")
    }).finally(() => {
      setLoading(false);
    });
  };

  const renderStats = () => {
    if (loading) return <Loading />;
    if (!replayData) return null

    return <ReplayStats replay={replayData} />
  };

  return (
    <q.div f1 w100 fctl gap={'20px'} pa="40px 0" className={cssNote}>
      <img src="/TM_Logo.png" alt="Trackmania logo" height={100} />
      <Spacer />
      <ReactMarkdown>
        {`
            This is the ActionKeys page.
            The goal of this page is to give an insight to how widely or not action keys are used.
            This is done by parsing a replay and looking at the action keys that are used.

            The GBX replay file contains all inputs that the player did.
            We can look at the inputs that matches 100% and see how many times the player used that exact input.
            If they have some abscure thing like 34.7% many times in a row, then we know they used a custom action key.
            This is not a 100% accurate way to detect custom action keys, but it is a good start.

            We will also allow excluding of ingame action keys, and add deadzones to avoid small changes in the input that the device could do to avoid detection, but too small to be human.

            The replay file is parsed by the server and the result is sent to the client.
            `}
      </ReactMarkdown>
      <Spacer />
      <q.div frtl gap={'20px'} ma="40px 0" w100>
        <q.div frtl w100>
          <TextField
            fullWidth
            value={searchBox}
            onChange={(x) => setSearchBox(x.target.value)}
            label="Enter GBX replay file"
          />
        </q.div>
        <Button variant="outlined" style={{ height: '100%', width: '250px' }} onClick={search}>
          Check replay
        </Button>
      </q.div>
      <Spacer />
      <q.div fctl w100>
        {renderStats()}
      </q.div>
    </q.div>
  );
};

export default ActionKeysPage;
