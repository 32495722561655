import axios from "axios";
import Urls from "../../config/urls";
import { ReplayData } from "../model/Replay";

export const fetchReplayData = (url) => {
    return new Promise<ReplayData>((resolve, reject) => {
        var apiUrl = Urls.GenerateUrl(Urls.Apis.ParseReplay({ url: url }))
        return axios.get<ReplayData>(apiUrl).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            console.log(error);
            reject(error);
        });        
    })    
}