import LeftNav from '../../layout/left-nav/left-nav';
import ActionKeysPage from '../actionkeys/actionkeys-page';

//const pages = [
//    {
//        name: "Action Keys",
//        component: ActionKeysPage,
//        path: "/actionkeys",
//        layout: LeftNav
//    }
//]

export const Root = () => {
  return (
    <LeftNav>
      <ActionKeysPage />
    </LeftNav>
  );
};

export default Root;
