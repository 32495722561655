import q from 'qjuul'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

interface IOwnProps {
    state?: boolean
}

const Loading = (props: IOwnProps) => {
    const { state = true } = props
    if (!state) return null;

    return (
        <q.div fccc w100 minh="150px">
            <CircularProgress />
        </q.div>
    )
}

export default Loading