export const quallityTiers = {
  S: {
    key: 'S',
    color: '#00ff00',
    tooltip: `S Tier means the player didn't use any action keys.`,
  },
  A: {
    key: 'A',
    color: '#ffff00',
    tooltip: `A Tier means the player used some action keys.`,
  },
  B: {
    key: 'B',
    color: '#ffa500',
    tooltip: `B Tier means the player used a fair amount of action keys.`,
  },
  C: {
    key: 'C',
    color: '#ff0000',
    tooltip: `C Tier means the player used some custom action keys before ban`,
  },
  D: {
    key: 'D',
    color: '#8b0000',
    tooltip: `D Tier means the player used a fair amount of custom action keys before ban`,
  },
  E: {
    key: 'E',
    color: '#800000',
    tooltip: `E Tier means the player something`,
  },
  F: {
    key: 'F',
    color: '#800000',
    tooltip: `F Tier means the player used custom keys after the ban`,
  },
};
