import { ActionType, createAction } from 'typesafe-actions';
import * as qs from 'query-string'

enum Internals {
  Home = '',
  About = 'about',
  Contact = 'contact',
  stats = 'stats',
}

const Apis =  {
  //Use =  GenerateUrl(ApiUrls.ExampleCall({call: 'val'}))
  //ExampleCall: createAction('/api/example/{call}/create')<{call: 'val'}>(),
  Base:  'https://api.sindrema.com/',
  ParseReplay: createAction('api/tm/replay/parse?url={url}')<{url: string}>(),
}

type UiActionsType = ActionType<typeof Apis>
const GenerateUrl = ( action: UiActionsType) => {
  var url = action.type as string
  const payload = (action as any)?.payload
  if (payload) {
    for (const key of Object.keys(payload)) {
      if (typeof payload[key] === 'object' && Array.isArray(payload[key])) {
        var query = qs.stringify({[key]: encodeURIComponent(payload[key])})
        url = url.replace(`{${key}}`, query)
      } else{
        url = url.replaceAll(`{${key}}`, encodeURIComponent(payload[key]))    
      }
    }
  }  
  return `${Apis.Base}${url}`
}

const Urls = {
  Apis,
  Internals,
  GenerateUrl
};

export default Urls;