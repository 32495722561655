/* eslint-disable react-hooks/rules-of-hooks */
import q from 'qjuul'
import React, { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector';
import MenuItem from './menuitem';

export const NavItems = [
    {
        name: 'Home',
        path: '#/',
        icon: 'home',
    },
    {
        name: 'Scan replay',
        path: '#/scan',
        icon: 'search',
    },
    {
        name: 'Leaderboard',
        path: '#/leaderboard',
        icon: 'table',
    },
    {
        name: 'About',
        path: '#/about',
        icon: 'info',
    },        
]


const NavMenu = () => {
    const { width, ref} = useResizeDetector();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    
    
    if (windowWidth < 2060) return null
  return (
    <q.div ref={ref} po="absolute" top='130px' le={`${-(width + 30)}px`} he="calc(100% - 80px)" notvisible={!width}>
        <q.div po='sticky' top='70px' le={`${-(width + 30)}px`} wi="300px" minh='100px' boRa='5px' ov='hidden' fctl gap='15px'>
            <q.div>Navigation</q.div>
            {
                NavItems.map((nav, index) => <MenuItem path={nav.path} label={nav.name} key={index} />)
            }
        </q.div>
    </q.div>
  )
}

export default NavMenu