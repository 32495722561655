import q from 'qjuul'
import React from 'react'
import { css } from '@emotion/css'
import { Link } from '@mui/material'

interface IOwnProps {
    label: string
    path: string
}

const MenuItem = (props: IOwnProps) => {
    const { label, path } = props
    const cssStyle = css`
        height: 40px;
        text-decoration: none !important;
        color: #fff !important;
        width: 100%;
        background-color: #212121;
        &:hover {
            background-color: #21212190;
            cursor: pointer;
        }
    `
    return (
        <Link href={path} className={cssStyle}>
            <q.div frcl h100 w100 shadow={{ baCo: '#21212130', size: '5px' }}>
                <q.div ma='0 20px'>
                    {label}
                </q.div>
            </q.div>
        </Link>
    )
}

export default MenuItem