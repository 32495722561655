import q from 'qjuul';
import { ReplayData } from '../../../shared/model/Replay';
import Chart from './chart';
import { combindedReplayData } from '../../../shared/formatters/graph';
import moment from 'moment';
import {css} from '@emotion/css'
import { quallityTiers } from '../../../shared/data/tierdata';
import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface IOwnProps {
  replay: ReplayData;
}

const ReplayStats = (props: IOwnProps) => {
  const { replay } = props;
  if (!replay) return null;

  const graphData = combindedReplayData(replay.inputs);

  const time = moment.duration(replay.raceTimeMS)
  const clearDisplay = moment.utc(time.as('milliseconds')).format('mm:ss:SSS')

  const cssStyle = css`
    h1 {
      margin: 0px;
    }
    p {
      margin: 0px;      
    }
    .MuiSvgIcon-root {
      font-size: 10px !important;
    }
  `

  
    const randomTier : typeof quallityTiers[keyof typeof quallityTiers] = quallityTiers[Object.keys(quallityTiers)[Math.floor(Math.random() * Object.keys(quallityTiers).length)]];

  const stats = [
    {
      label: 'Quallity',
      value: randomTier.key,
      color: randomTier.color,
      surfix: 'Tier',
      info: randomTier.tooltip,
    }
  ]


  return (
    <>
      <q.div h100 w100 gap="20px" fctl className={cssStyle}>
        <q.div frtl w100 fsb id='statsHeader'>
          <q.div>
            <q.h1 ma="0px">Ghost analysis for {replay.authorName}</q.h1>
            <q.p ma='0px' op="0.5">Replay UId: {replay.replayUid} | Challange UId: {replay.validate_ChallangeUid}</q.p>
          </q.div>
          <q.div>
            <q.h1>{clearDisplay}</q.h1>
            <q.p ma='0px' op="0.5">{replay.location}</q.p>
          </q.div>
        </q.div>

        <q.div fctl >
          {
            stats.map((stat, index) => (
              <q.div frtl >
                <q.p wi='100px'>{stat.label}</q.p>
                <q.p co={stat.color}>{stat.value} {stat.surfix}</q.p>
                {
                  stat.info && (
                    <Tooltip title={stat.info}>
                      <q.div wi='10px' ma='0 0 0 5px' h100 w100 frtr>
                        <Info />
                      </q.div>
                    </Tooltip>
                  )
                }                
              </q.div>
            ))
          }
        </q.div>
        
        <q.div w100 fctl gap='20px'>
          <q.h3>Input data over time</q.h3>
          <Chart data={graphData.speed} _key="speed" />
          <Chart data={graphData.steer} _key="steer" />
          <Chart data={graphData.rpm} _key="rpm" />        
          <Chart data={graphData.gear} _key="gear" />        
        </q.div>
        
      </q.div>
    </>
  );
};

export default ReplayStats;
