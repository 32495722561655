import moment from "moment";
import { Input } from "../model/Replay";


const truncateDecimals = (number: number ) => {
    return Math[number < 0 ? 'ceil' : 'round'](number)
}

const formatInputKey = (val: Input, key: string) => {
    const value = val[key]

    return ({
        timeFrame: moment(val.timestampMS ?? 0).valueOf(),
        value: truncateDecimals(value) ?? 0,
    })
}

const formatKey = (inputs: Input[], key: string) => inputs?.map((x) => formatInputKey(x, key))

export const combindedReplayData = (inputs: Input[]) => ({
    rpm: formatKey(inputs,'rpm'),
    speed: formatKey(inputs,'speed'),
    steer: formatKey(inputs,'steer'),    
    gear: formatKey(inputs,'gear'),    
})